.logoText {
  font-family: fantasy;
  padding-right: 30px;
}

.homepage-main-head {
  display: flex;
  justify-content: end;

  font-weight: bold;
}
.main-heading-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}
.main-heading-text img {
  width: 95%;
}
.custom-main-heading-text img {
  width: 100%;
  max-width: 900px;


}


.home-page-sections-wrapper {
  display: flex;
}
.home-left-section,
.home-right-section {
  width: 50%;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  text-align: center;
}
/* .sub-captions h4 {
  font-size: 30px;
  font-weight: 900;
} */
.sub-captions img{
  max-width: 70%;
  width: 100%;
}
.sub-caption-wrapper-one{
padding-bottom: 40px;
padding-top: 30px;
}
.sub-caption-wrapper-two{
  padding-top: 40px;
}
.women-icon-wrapper img {
  max-height: 460px;
  max-width: 460px;
  width: 100%;
}
.multipe-logos img,
.single-logos img {
  max-height: 60px;
}
.other-details {
  text-align: start;
  padding-left: 50px;
  padding-bottom: 50px;
}
.other-details span {
  /* font-size: 12px; */
  font-weight: 900;
}
.other-details p {
  font-size: 12px;
}
.home-page-back-button {
  visibility: hidden;
}
.home-page-home-left-section {
  line-height: 10px;
}
.logoWrapper img {
  height: 20px;
  max-width: 110px;
  width: 100%;
  padding-right: 50px;
}
.mobile-home-right-section{
  display: none;
}

@media only screen and (max-width: 1050px) {
  .home-page-home-left-section {
    line-height: normal;
  }
  
}

/* media query */
@media only screen and (max-width: 1000px) {
  .home-page-sections-wrapper {
    display: block;
  }
  .home-left-section,
  .home-right-section {
    width: 100%;
  }
  .multipe-logos img,
  .single-logos img {
    max-height: 40px;
  }
  .sub-captions h4 {
    font-size: 20px;
    font-weight: 700;
  }
  .mobile-home-right-section{
    display: flex;
  }
  .hide-mobile-home-right-section{
    display: none;
  }


}

@media only screen and (max-width: 1300px) {
  .other-details {
  
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 650px) {
  .other-details {
    padding-left: 10px;

  }
  /* .main-heading-text img {
    height: 15px;
  } */
  .logoWrapper img {
    height: 14px;
    max-width: 82px;
    width: 100%;
    padding-right: 50px;
}


}


