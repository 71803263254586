.details-home-left-section {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.details-home-right-section {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.choosed-color-image img,
.qr-code-wrapper img,
.person-image-wrapper img {
  max-height: 250px;
  max-width: 280px;
  width: 100%;
}
.qr-code-wrapper {
  padding: 3px;
}
.top-caption-details-page {
  text-align: center;
  font-size: 25px;
  line-height: 10px;
  color: white;
}
.detail-hightlighted-caption {
  color: #ffffff;
}
.image-with-person-qr-detail-main-wrapper {
  display: flex;
  width: 100%;
}
.container1,
.container2 {
  width: 50%;
  background-color: #5c1ba0;
}
.image-with-person-details,
.qr-code-with-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.qr-code-with-text {
  color: white;
  font-size: 25px;
}

.person-details {
  background-color: #818181;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 400px;
  height: 94%;
}

.container {
  display: flex;
  height: 250px;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #818181;
  color: white;
  padding: 10px;
}

.image {
  height: 250px;
  width: auto;
  max-width: 200px;
}
.thyroid-text-qr-code {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image {
  height: 250px;
  width: auto;
  max-width: 200px;
}

.image-qr {
  max-width: 230px;
  max-height: 230px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
}
.thyroid-text-qr-code h1 {
  font-size: 30px;
  font-weight: 400;
  color: white;
  padding-right: 10px;
}

@media only screen and (max-width: 1500px) {
  .top-caption-details-page {
    font-size: 20px;
    line-height: normal;
  }

  .details-home-left-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .details-home-right-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .container1,
  .container2 {
    width: 100%;
    margin-top: 10px;
  }
}




@media only screen and (max-width: 1100px) {
  .image-with-person-qr-detail-main-wrapper {
    display: block;
  }
}


@media only screen and (max-width: 1000px) {
    /* .custom-other-details-choosecolor-page {
        padding-top: 500px;
    
    
    } */
  }
  





@media only screen and (max-width: 650px) {
    .choosed-color-image img{
        padding-top: 25px;
    }
    .details-home-left-section{
        padding-top: 80px;
    }
    .right h2{
        font-size: 18px;
    }
  }

@media only screen and (max-width: 450px) {
  .thyroid-text-qr-code h1 {
    font-size: 18px;

    padding-right: 5px;
  }
  .container1, .container2,.image {
    max-height: 180px;
}
.image-qr {
    max-width: 160px;
    max-height: 160px;
}
.right h2{
    font-size: 15px;
}
.right p{
    font-size: 12px;
}

}
@media only screen and (max-width: 330px) {
    .thyroid-text-qr-code h1 {
      font-size: 14px;
      padding-right: 3px;
    }
    .container1, .container2,.image {
      max-height: 140px;
  }
  .image-qr {
      max-width: 100px;
      max-height: 100px;
  }
  .details-page-custom-wrapper{
    height: 210vh;
  }
  .left{
      display: flex;
      justify-content: center;
      align-items: center;
  }
  /* .custom-other-details-choosecolor-page {
    padding-top: 280px;


} */
  }


  /* display: flex;
    justify-content: center;
    align-items: center; */