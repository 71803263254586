.butterfly-container {
  position: absolute;
  bottom: 0;
  right: 0;
  animation: fly 5s ease-in-out forwards;
}

@keyframes fly {
  0% {
    transform: translateX(0) translateY(0) scale(0.5);
  }
  50% {
    transform: translateX(100px) translateY(-50vh) scale(1.5);
  }
  100% {
    transform: translateX(0) translateY(-150vh) scale(0.5);
  }
}

.butterfly {
  width: 160px; 
  height: auto; 
  position: relative;
}

.end .butterfly {
  display: none; 
}

@media only screen and (max-width: 1000px) {
    .butterfly-container {
      position: absolute;
      bottom: 0;
      left: 0;
      animation: fly 5s ease-in-out forwards;
    }
  
    @keyframes fly {
      0% {
        transform: translateX(0) translateY(0) scale(0.5);
      }
      50% {
        transform: translateX(-100px) translateY(-50vh) scale(1.5); /* Changed to negative value */
      }
      100% {
        transform: translateX(-0px) translateY(-150vh) scale(0.5); /* Changed to negative value */
      }
    }
  
    .butterfly {
      width: 80px; /* Initial width of the butterfly */
      height: 80px; /* Adjust height proportionally */
      position: relative;
    }
  }
  
