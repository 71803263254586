/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.main-content {
  margin-top: 50px;
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  z-index: 99;
 
}

.main-head {
  height: 70px;
  background-color: #e8378b;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-bottom {
  height: 35px;
  background-color: #e8378b;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
}

.next-and-backward-button-wrapper {
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
}
.back-button,
.next-button {
  height: 45px;
  background-color: #3c2b99;
  color: white;
  border-radius: 50px;
  max-width: 150px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin-top: -25px;
  font-size: 30px;
  font-weight: 900;
  cursor: pointer;
  /* gap: 10px; */
}
.next-arrow-icon,
.back-arrow-icon {
  height: 37px;
  background-color: white;
  max-width: 37px;
  width: 100%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.back-button p {
  padding-right: 10px;
}
.next-button p {
  padding-left: 10px;
}
.backward-forward-icon {
  color: #3c2b99;
}

@media only screen and (max-width: 650px) {
  .back-button,
  .next-button {
    height: 40px;
    font-size: 20px;
    font-weight: 700;
    max-width: 125px;
    /* gap: 10px; */
  }
  .next-arrow-icon,
  .back-arrow-icon {
    height: 32px;
    width: 32px;
  }
  .back-button p {
    padding-right: 10px;
  }
  .next-button p {
    padding-left: 10px;
  }
  .backward-forward-icon {
    color: #3c2b99;
  }
  .next-and-backward-button-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
  .main-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
