.other-details-join-form {
  padding-left: 80px;
  padding-bottom: 50px;
}
.women-icon-wrapper-join-form img {
  max-height: 380px;
  max-width: 380px;
  width: 100%;
}

.text-merck {
  visibility: hidden;
}
.main-head-join-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
}

.join-form-main-wrapper p {
  color: #ec2991;
  font-size: 27px;
}
.custom-join-form-text {
  font-weight: 900;
  color: #5c1ba0;
}

.sup-back {
  font-size: 15px;
}
.bottom-sup-content-join-form {
  text-align: center;
  line-height: 10px;
}

.bottom-sup-content-join-form p {
  font-size: 35px;
}
.form-inputs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.form-inputs-wrapper input,
.form-inputs-wrapper select {
  max-width: 270px;
  width: 100%;
  height: 30px;

  background: linear-gradient(
    to right,
    #d3d3d354 0%,
    #d3d3d300 30%,
    rgba(255, 255, 255, 0.781) 30%,
    rgba(255, 255, 255, 0.781) 70%,
    #d3d3d300 70%,
    #d3d3d354 100%
  );
  border-radius: 5px;
  border: 1px solid rgba(214, 214, 214, 0.829);
  padding-left: 20px;
}
.form-inputs-wrapper input::placeholder {
  color: black;
}
.form-inputs-wrapper select {
  max-width: 295px;
  height: 33px;
}
.error {
  color: red;
  font-size: 10px;
}
.heading-page-two {
  height: 40px;
  width: 100%;
}
.form-page-home-left-section {
  line-height: 10px;
}
.two-line-main-heading-text {
  display: none;
}

.form-page-mobile-title {
  display: none;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media only screen and (max-width: 1200px) {
  .form-page-home-left-section {
    line-height: normal;
  }
  .bottom-sup-content-join-form {
    line-height: normal;
  }
  .join-form-main-wrapper p {
    display: none;
  }
  .form-page-mobile-title {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 650px) {
  .text-merck {
    display: none;
  }
  .other-details-join-form {
    padding-left: 27px;
  }
  .heading-page-two {
    height: 30px;
    width: 84%;
  }
  .form-page-main-head-desktop {
    display: none;
  }
  .two-line-main-heading-text {
    display: flex;
    flex-direction: column;
  }
}
