.thank-you-home-page-sections-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thank-you-women-image {
  max-height: 350px;
  max-width: 350px;
  object-fit: contain;
  width: 100%;
}
.thank-you-title {
  max-width: 1200px;
  width: 100%;
  padding-bottom: 50px;
}
.disclaimer {
  height: auto;
  width: 100%;
  padding-top: 440px;
}
.disclaimer img {
  height: auto;
  width: 100%;
}
.thank-you-title-mobile {
  display: none;
}
.disclaimer .other-details-join-form {
  /* padding-bottom: ; */
  padding-left: 0px;
}
/* users butterfly */
.butterfly-main-wrap {
  position: relative;
  max-height: 500px;
  max-width: 500px;
  width: 100%;
  flex-wrap: nowrap;
}
.docotors-images-wrapper {
  position: absolute;
  max-height: 500px;
  max-width: 500px;
  width: 100%;
  position: absolute;
  padding-top: 40px;
}
.blank-first{
  width: 51px !important;
  height: 105px;

}
.blank{
  width: 60px !important;
  height: 105px;
}
.image-two{
  width: 98px !important;
}
.blank-second{
  width: 26px !important;
}
.image-third{
  width: 100px !important;
}
.docotors-images-wrapper img {
  height: 109px;
  width: 80px;
}

.boundary-butterfly-wrapper {
  position: absolute;
}
.mainwrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.boundary-butterfly-wrapper img {
  max-height: 500px;
  max-width: 500px;
  width: 100%;
}

/* users butterfly end */
.total-count p {
  text-align: center;
  font-size: 25px;
}
.blank-show-mobile{
  display: none;
}

@media only screen and (max-width: 1000px) {
  .thank-you-home-page-sections-wrapper {
    padding-top: 50px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 650px) {
  .thank-you-home-page-sections-wrapper {
    padding-top: 100px;
  }
  .thank-you-title {
    display: none;
  }
  .thank-you-title-mobile {
    display: flex;
  }
}

@media only screen and (max-width: 540px) {
  .hide-on-mobile-docotorimages {
    display: none;
  }

  .docotors-images-wrapper {
    position: absolute;
    width: 100%;
    padding-top: 27px;
  }


}
@media only screen and (max-width: 520px) {
 
  .docotors-images-wrapper img {
    height: 90px;
    width: 80px;
  }
  .image-two{
    width: 58px !important;
  }
  .image-third{
    width: 50px !important;
  }
}
/* @media screen and (min-width: 460px) and (max-width: 540px) {
  .hidden-blank-mobile{
    display: none;
  }
} */
@media only screen and (max-width: 540px) {
  .hidden-blank-mobile{
    display: none;
  }
}

@media only screen and (max-width: 470px) {
  .disclaimer {
    padding-top: 340px;
  }
  .docotors-images-wrapper img {
    height: 76px;
    width: 63px;
}

}

@media only screen and (max-width: 400px) {
  .docotors-images-wrapper img {
    height: 66px;
    width: 48px;
}
}
@media only screen and (max-width: 420px) {
  .disclaimer {
    padding-top: 307px;
  }

}


@media only screen and (max-width: 380px) {
  /* .docotors-images-wrapper img {
    height: 20px;
    width: 18px;

    height: auto;
  } */
  .disclaimer {
    padding-top: 280px;
  }
  .docotors-images-wrapper {
    padding-top: 14px;
   
  
  }
}
@media only screen and (max-width: 350px) {
  .docotors-images-wrapper img {
    height: 65px;
    width: 40px;
}
}
@media only screen and (max-width: 320px) {
  .docotors-images-wrapper img {
    height: 55px;
    width: 40px;
}
}
@media only screen and (max-width: 320px) {
  .docotors-images-wrapper img {
    height: 45px;
    width: 35px;
}
}
@media only screen and (max-width: 280px) {
.hidden-image-xs{
  display: none;
}
}