.choose-color-header {
  text-align: center;
  line-height: 5px;
}
.choose-color-main-head {
  display: flex;
  justify-content: space-around;
}
.left-choose-color-header img {
  max-width: 600px;
  width: 100%;
  max-height: 60px;
}
.choose-color-header p {
  font-size: 22px;
}
.custom-choose-color-text {
  background-color: #5c1ba0;
  color: white;
}
.color-options-wrapper {
  display: flex;
  justify-content: center;
  gap: 50px;
}
.color-options-wrapper img {
  max-width: 200px;
  height: 150px;
  width: 100%;
  cursor: pointer;
  padding: 7px;
}
.choose-color-main-content {
  padding-top: 50px;
  height: 100vh;
}

.choose-color-text-below-options {
  text-align: center;
  font-size: 30px;
  margin-top: -50px;
  margin-bottom: 20px;
}

.choose-file-right-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.choose-file-wrapper {
  max-width: 400px;
  width: 100%;
  padding: 40px;
  background-color: rgb(136, 136, 136);
}

.file-sector-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}
.user-icon-file-upload {
  color: rgba(106, 106, 106, 0.645);
  height: 100%;
  width: 100%;
  background-color: black;
  cursor: pointer;
}
.uploaded-image {
  max-height: 400px;
  width: auto;
  max-width: 100%;
}
.heading-2-page-4 {
  max-width: 600px;
  width: 100%;
  max-height: 60px;
}
.choose-color-home-left-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.warning-message {
  font-size: 12px;
  color: rgba(255, 0, 0, 0.87);
}
.clickable-color {
  border-radius: 10px;
}
/* .butterfly-wrapper {
  position: relative;
} */

.butterfly-wrapper-mobile{
  display: none;
  height: 40px;
  width: 100%;

}
.file-input{
  background-color: white;
  margin-top: 20px;
  cursor: pointer;
}



@media only screen and (max-width: 1000px) {
  .choose-color-header {
    line-height: normal;
  }

  .left-choose-color-header h2 {
    font-family: math;
    font-size: 20px;
  }
  .choose-color-header p {
    font-size: 15px;
  }
  .choose-color-main-content {
    padding: 70px;
    height: 100%;
  }
    .butterfly-wrapper-desktop{
    display: none;
  }
  .butterfly-wrapper-mobile{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-left: 600px;
  }
  .choose-file-wrapper{
    margin-bottom: 100px;
  }

}




@media only screen and (max-width: 650px) {
  .color-options-wrapper img {
    max-width: 170px;
    height: 120px;
  }
  .choose-color-main-content {
    padding: 30px;
  }
  .choose-color-home-left-section {
    padding-top: 100px;
  }

  .choose-color-main-head {
    display: flex;
    justify-content: space-around;
    height: 140px;
    flex-direction: column;
  }
  .left-choose-color-header img {
    max-height: 50px;
  }
  .butterfly-wrapper-mobile{
   
    padding-left: 300px;
  }
}

@media only screen and (max-width: 450px) {
  .clickable-color {
    padding-right: 15px;
  }
  .choose-color-main-content {
    padding: 30px;
    
  }
  .choose-color-text-below-options h3{
    font-size: 16px;
  }
}

@media only screen and (max-width: 350px) {
  .color-options-wrapper img {
    max-width: 160px;
    height: 70px;
  }
  .choose-color-main-content {
    padding: 30px;
  }
}
